import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Button, Chip, CircularProgress, Grid, IconButton, Table, Typography } from "@mui/joy"

import MapIcon from '@mui/icons-material/Map';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { Layout } from "../components/Layout"
import { Sidebar } from "../components/Sidebar"
import { Header } from "../components/Header"

import moment from "moment";

import Pagination from "../components/Pagination";

import { ModalNotificaciones } from "../modals/ModalNotificaciones";
import { ModalRutaActuario } from "../modals/ModalRutaActuario";

import { MisRutas } from "../connections/comun/RutaConnection";

import { RutaProps } from "../interfaces/comun/RutaInterface";
import { NotificacionFullProps, NotificacionProps } from "../interfaces/comun/NotificacionInterface";

export const MisRutasPage = () => {

    const navigate = useNavigate();
    
    const [open, setOpen] = useState<boolean>( false );
    const [openRuta, setOpenRuta] = useState<boolean>( false );

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState( 10 );

    const [rows, setRows] = useState<RutaProps[]>( [] );
    const [notificaciones, setNotificaciones] = useState<NotificacionProps[]>( [] );
    const [notificacionesRuta, setNotificacionesRuta] = useState<NotificacionFullProps[]>( [] );

    const [isLoading, setIsLoading] = useState<boolean>( false );

    const handleNotificaciones = ( ruta: RutaProps ) => {

        const newRows: NotificacionProps[] = ruta.notificaciones?.map( ( e ) => {
            return {
                referencia: e?.referencia ?? '',
                personaNotificar: e?.persona ?? '',
                domicilioNotificar: e?.domicilio ?? '',
                tipoNotificacion: e?.tipoNotificacion ?? '',
                tipoAcuerdo: e?.tipoAcuerdo ?? '',
                tipoParte: e?.tipoParte ?? '',
            };
        }) ?? [];

        setNotificaciones( newRows );
    }
    
    useEffect(() => {
    
        async function obtener(){

            setIsLoading( true ); 

            await MisRutas().then( resp =>  {

                setTimeout(() => {
                    
                    setRows( resp );
                    setIsLoading( false ); 
                    
                }, 400);

            });

        }
       
        obtener();          

    }, [ ])   

    return (
        
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Sidebar />
            
            <Header />       
       
            <Layout title='Mis Rutas'>

                <Grid container >                   

                    <Grid xs={12}>                     

                        <Table 
                            aria-label="striped table" 
                            stripe={'even'} 
                            borderAxis='x'
                            size='lg' 
                            sx={{ 
                                width: { xs: '100%', md: '100%' },
                                display:{ xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >      

                            <thead>

                                <tr>       
                                    <th style={{ width: '10%', textAlign: 'center' }}>Número de <br /> ruta</th>                           
                                    <th style={{ width: '15%' }}>Fecha asignación</th>
                                    <th style={{ width: '15%' }}>Fecha inicio</th>                                   
                                    <th style={{ width: '15%', textAlign: 'center' }}>Zona</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Número de <br /> notificaciones</th>
                                    <th style={{ width: '15%', textAlign: 'center' }}>Ver ruta</th>                            
                                    <th style={{ width: '15%', textAlign: 'center' }}>Estatus</th>                            
                                    <th style={{ width: '5%', textAlign: 'center' }}>Detalle</th>
                                </tr>

                            </thead>

                            <tbody>

                                {

                                    isLoading
                                    ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '250px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                    :   
                                        (
                                            rowsPerPage > 0
                                            ? rows.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                            : rows
                                        )
                                        .map( ( row ) => (
                                            <tr key={ row.id }>                                        
                                            
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Ruta-{ row.idRuta }</td>        
                                                
                                                <td>{ moment( row.fecha ).format('DD-MM-YYYY h:mm a') }</td>

                                                <td>{ row.fechaInicio ? moment( row.fechaInicio ).format('DD-MM-YYYY h:mm a') : '' }</td>   

                                                <td style={{ textAlign: 'center' }}>{ row.zona }</td>                                        
                                                                                    
                                                <td style={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>
                                                    
                                                    <Button 
                                                        variant="solid" 
                                                        color="neutral" 
                                                        size='sm'
                                                        endDecorator={ <FormatListBulletedIcon /> }
                                                        onClick={ () => { handleNotificaciones( row ); setOpen( true ); } }
                                                    >
                                                        { row.notificaciones?.length }
                                                    </Button>                                            
                                                    
                                                </td> 

                                                <td style={{ textAlign: 'center' }}>

                                                    <Button 
                                                        variant="soft" 
                                                        color="neutral" 
                                                        startDecorator={ <ArrowForwardIcon />  }
                                                        endDecorator={ <MapIcon /> }
                                                        onClick={ () => { setNotificacionesRuta( row?.notificaciones ?? [] ); setOpenRuta( true ); } }
                                                    >
                                                        Ver ruta
                                                    </Button>
                                                
                                                </td> 

                                                <td style={{ textAlign: 'center' }}>
                                                    <Chip variant='outlined' size='lg'>
                                                        { row.estatus }
                                                    </Chip>
                                                </td>            
                                                
                                                <td style={{ textAlign: 'center' }}>
                                                    <IconButton variant="solid" onClick={ () => navigate(`/ruta/${ row.id }`)}>
                                                        <ArrowForwardIcon /> 
                                                    </IconButton>
                                                </td> 

                                            </tr>
                                        ))
                                }

                                {
                                    ( !isLoading && rows.length === 0 )
                                    &&
                                        <tr>
                                            <td colSpan={8} style={{ textAlign: 'center', padding: '50px' }}>
                                                <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                            </td>
                                        </tr>
                                }

                            </tbody>                          

                        </Table>     
                    
                    </Grid>

                </Grid>              

                <Pagination 
                    gotoPage={ setPage }
                    length={ rows.length }
                    pageSize={ rowsPerPage }
                    setPageSize={ setRowsPerPage }                            
                />                          
        
            </Layout>     

            <ModalNotificaciones 
                open={ open }
                setOpen={ setOpen}
                notificaciones={ notificaciones }
            />   

            <ModalRutaActuario 
                open={ openRuta }
                setOpen={ setOpenRuta }
                notificaciones={ notificacionesRuta }
            />           

        </Box>

    )
}
