import { useEffect, useState } from 'react';

import { Box, Button, Card, CardContent, CircularProgress, FormControl, FormLabel, Grid, Input, Snackbar, Switch, Typography } from '@mui/joy';

import WarningIcon from "@mui/icons-material/Warning";

import { Sidebar } from '../../components/Sidebar';

import { Header } from '../../components/Header';

import { Layout } from '../../components/Layout';

import { ModalNuevoUsuario } from '../../modals/ModalNuevoUsuario';

import { ActivarUsuario, ObtenerUsuarios } from '../../connections/admin/UsuarioConnection';

import { SnackbarProps } from '../../interfaces/ui/Snackbar';
import { UsuarioProps } from '../../interfaces/admin/UsuarioInterface';

type OptionType = 'Agregar' | 'Editar' ; 

export const UsuariosPage = () => {

    const [open, setOpen] = useState<boolean>( false );
    const [opcion, setOpcion] = useState<OptionType>( 'Agregar' );

    const [isLoading, setIsLoading] = useState<boolean>( true );

    const [rows, setRows] = useState<UsuarioProps[]>( [] );
    const [rowsAll, setRowsAll] = useState<UsuarioProps[]>([ ]);

    const [referencia, setReferencia] = useState<string>( '' );
    const [valueFilter, setValueFilter] = useState<string>( '' );
    
    const [{ type: typeSnackbar, open: openMessage, message }, setOpenMessage] = useState<SnackbarProps>({
        type: 'warning',
        message: '',
        open: false,
    });

    const handleClose = () => setOpenMessage({ type: typeSnackbar, open: false, message })

    const cambiarEstatus = async ( { id }: UsuarioProps, activar: boolean ) => {

        await ActivarUsuario( { id, activar } ).then( resp => {          
                
            if( resp.success ){

                const newArray = rows.map( ( u ) => {
                    if( u.id === id ){
                        u.activo = activar;
                    }
                    return u;
                });

                setRows( newArray );
            }
            else {
                setOpenMessage({ type: 'warning', open: true, message: resp.message ?? '' });
            }  

        });
    }

    const filter = () => {

        if( valueFilter !== '' ){

            const usuariosFilter = rowsAll.filter( function ({ nombreCompleto, puesto }) { 

                const nombreCompletoFilter = nombreCompleto.toLowerCase().indexOf( valueFilter.toLowerCase() );    
                
                if( puesto ){
                    const puestoFilter = puesto.toLowerCase().indexOf( valueFilter.toLowerCase() );    
                    return nombreCompletoFilter !== -1 || puestoFilter !== -1;
                }    
                else{
                    return nombreCompletoFilter !== -1;
                }        
            });

            setRows( usuariosFilter );
        }
        else{
            setRows( rowsAll );
        }

    }

    useEffect(() => {
    
        async function obtener(){

            setIsLoading( true );
            setRows( [] );

            await ObtenerUsuarios().then( resp => {

                setTimeout(() => {
    
                    setRows( resp );
                    setRowsAll( resp );
                    setIsLoading( false );
                    
                }, 400);

            });

        }

        obtener();  

    }, [ ])   

    return (   
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Snackbar
                key={ 'snackbar-login' }
                autoHideDuration={2000}
                color={ typeSnackbar }
                size="md"
                variant="solid"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={ openMessage }
                onClose={ handleClose }
                startDecorator={ <WarningIcon /> }
                sx={{ zIndex: 999999999 }}
            >
                { message }
            </Snackbar>

            <Sidebar />
            
            <Header />

            <Layout
                title={ `Usuarios (${ rowsAll.length })` }
            >

                <Grid container spacing={3} >

                    <Grid xs={12} textAlign={'end'}>

                        <Button
                            variant="solid"
                            color="neutral"     
                            onClick={ () => { setOpcion( 'Agregar' ); setOpen( true );} }
                            disabled={ isLoading }
                        >
                            Agregar nuevo usuario
                        </Button>

                    </Grid>

                    <Grid xs={12}>

                        <FormControl disabled={ isLoading }>
                            <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                Buscar usuario
                            </FormLabel>
                            <Input
                                sx={{ '--Input-decoratorChildHeight': '45px' }}
                                placeholder=""     
                                autoComplete="off" 
                                size="lg"
                                value={ valueFilter }   
                                onChange={ ( e ) => setValueFilter( e.target.value ) }       
                                onKeyUp={ filter }                 
                            />        
                        </FormControl>

                    </Grid>

                    {

                        isLoading
                        ?
                            <Grid xs={12} sx={{ textAlign: 'center', p: '200px' }}>
                                <CircularProgress color='neutral' size='md' />
                            </Grid>
                        :
                
                            rows.map( ( row, index ) => (

                                <Grid key={ index } xs={12} sm={6} lg={3}>                                 

                                    <Card variant="outlined" >
                                        
                                        <CardContent>

                                            <Grid container spacing={1}>

                                                <Grid xs={12} sx={{ textAlign: 'center' }}>
                                                   
                                                    <img
                                                        src={
                                                            row.imagenPerfil
                                                            ? row.imagenPerfil
                                                            : "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                                                        }                                              
                                                        alt=""
                                                        style={{ width: 95, height: 95, borderRadius: 100 }}
                                                    />

                                                </Grid>

                                                <Grid xs={12} textAlign={'center'}>

                                                    <Typography level="title-md" id="card-description">
                                                        ({ row.idUsuario }) { row.nombreCompleto } 
                                                    </Typography>                                                
                                                 
                                                </Grid>
                                                
                                                <Grid xs={12}>
                                                 
                                                    <Typography level="body-sm" aria-describedby="card-description" mt={1}>
                                                        <strong>Correo electrónico:</strong> <br />
                                                        { row.correoElectronico }
                                                    </Typography>      

                                                    <Typography level="body-sm" aria-describedby="card-description" mt={2}>
                                                        <strong>Tipo de usuario:</strong> { row.tipoUsuario }
                                                    </Typography>   

                                                    {
                                                        row.puesto
                                                        &&
                                                            <Typography level="body-sm" aria-describedby="card-description" mt={2}>
                                                                <strong>Puesto:</strong> { row.puesto }
                                                            </Typography>                                        
                                                    }                        

                                                </Grid>

                                                <Grid xs={6} mt={2}>

                                                    <FormControl orientation="horizontal">
                                                        <FormLabel sx={{ fontSize: 16 }}>Activar</FormLabel>
                                                        <Switch 
                                                            size='lg' 
                                                            sx={{ mr: 12 }} 
                                                            color={ row.activo ? 'success' : 'neutral'}
                                                            checked={ row.activo } 
                                                            onChange={ ( e ) => cambiarEstatus( row, e.target.checked ) } 
                                                        />
                                                    </FormControl>       

                                                </Grid>

                                                <Grid xs={6} textAlign={'right'} mt={2}>

                                                    <Button size='sm' variant="solid" color="neutral" onClick={ () => { setOpcion( 'Editar' ); setReferencia( row.id ); setOpen( true ); } } >
                                                        Editar
                                                    </Button>

                                                </Grid>

                                            </Grid>                                           

                                        </CardContent>                                        

                                    </Card>

                                </Grid>

                            ))
                    }

                </Grid>

            </Layout>
            
            <ModalNuevoUsuario 
                open={ open }            
                setOpen={ setOpen }
                opcion={ opcion }
                id={ referencia } 
                setOpenMessage={ setOpenMessage }
                usuarios={ rows }
                setUsuarios={ setRows }
            />

        </Box>
    );
}
