import { useEffect, useState } from "react";

import { Modal, ModalOverflow, ModalDialog, Grid, Card, Typography, Divider, CardContent, FormControl, FormLabel, RadioGroup, Select, List, Radio, Input, Button, Switch, IconButton, DialogActions, Option, Alert, CircularProgress } from "@mui/joy";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import ReactSelect, { components } from 'react-select';

import moment from "moment";

import { ObtenerDescripcionEntidad } from "../helpers/ObtenerClaveEntidad";
import { curpFechaNacimiento, curpValida } from "../helpers/ObtenerFechaNacimiento";
import { ClearFormatPhoneNumber, FormatPhoneNumber } from "../helpers/FormatPhoneNumber";
import { colourStyles } from "../helpers/StyleReactSelect";

import { ActualizarUsuario, AgregarUsuario, BuscarUsuarioRH, ObtenerUsuario } from "../connections/admin/UsuarioConnection";
import { ObtenerTiposUsuarios } from "../connections/catalogos/TipoUsuarioConnection";
import { ObtenerPuestos } from "../connections/catalogos/PuestoConnection";
import { ObtenerRoles } from "../connections/catalogos/RolConnection";

import { SnackbarProps } from "../interfaces/ui/Snackbar";
import { ActualizarUsuarioParams, AgregarUsuarioParams, UsuarioProps } from "../interfaces/admin/UsuarioInterface";
import { TipoUsuarioInterface } from "../interfaces/catalogos/TipoUsuarioInterface";
import { PuestoInterface } from "../interfaces/catalogos/PuestoInterface";

type OptionType = 'Agregar' | 'Editar' ; 

interface Props{
    open:       boolean;
    setOpen:    React.Dispatch<React.SetStateAction<boolean>>;
    opcion:     OptionType;
    id?:        string;
    setOpenMessage: React.Dispatch<React.SetStateAction<SnackbarProps>>;
    usuarios: UsuarioProps[];
    setUsuarios: React.Dispatch<React.SetStateAction<UsuarioProps[]>>;
}

interface Errors {
    curp?:                  string;
    api?:                   string;
    nombres?:               string;
    apellido1?:             string;
    correoElectronico?:     string;
    tipoUsuario?:           string;
    puesto?:                string;
    telefonoCelular?:       string;
    contrasena?:            string;
    contrasenaFirma?:       string;
    idFirmaElectronica?:    string;
    fechaExpiraFirma?:      string;
}

interface ReactSelectProps {
    value: number;
    label: string;
}

export const ModalNuevoUsuario = ( { open, setOpen, opcion, id, setOpenMessage, usuarios, setUsuarios }: Props ) => {

    const [buscarUsuario, setBuscarUsuario] = useState<boolean>( false );
    const [curpSearch, setCurpSearch] = useState<string>('');
    const [isLoadingBuscar, setIsLoadingBuscar] = useState<boolean>( false );
    const [isSaving, setIsSaving] = useState<boolean>( false );
    const [isLoading, setIsLoading] = useState<boolean>( false );

    const [referencia, setReferencia] = useState<string>('');
    const [curp, setCurp] = useState<string>('');
    const [nombres, setNombres] = useState<string>('');
    const [apellido1, setApellido1] = useState<string>('');
    const [apellido2, setApellido2] = useState<string>('');
    const [correoElectronico, setCorreoElectronico] = useState<string>('');
    const [telefonoCelular, setTelefonoCelular] = useState<string>('');

    const [tipoUsuario, setTipoUsuario] = useState<number>(0);
    const [tipoUsuarioTemp, setTipoUsuarioTemp] = useState<number>(0);
    const [tiposUsuariosArray, setTiposUsuariosArray] = useState<TipoUsuarioInterface[]>( [] );

    const [puesto, setPuesto] = useState<number>(0);
    const [puestoTemp, setPuestoTemp] = useState<number>(0);
    const [puestosArray, setPuestosArray] = useState<PuestoInterface[]>( [] );

    const [idFirma, setIdFirma] = useState<string>('');
    const [contrasenaFirma, setContrasenaFirma] = useState<string>( '' );
    const [fechaExpiraFirma, setFechaExpiraFirma] = useState<string>( '' );
    const [viewContrasenaFirma, setViewContrasenaFirma] = useState<boolean>( false );

    const [contrasena, setContrasena] = useState<string>( '' );
    const [viewContrasena, setViewContrasena] = useState<boolean>( false );

    const [confirmarContrasena, setConfirmarContrasena] = useState<string>( '' );
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState<boolean>( false );

    const [checkedActivar, setCheckedActivar] = useState<boolean>(true);
    const [checkedRestablecer, setCheckedRestablecer] = useState<boolean>(true);

    const [roles, setRoles] = useState<ReactSelectProps[]>( [ ] );
    const [rolesSelected, setRolesSelected] = useState<ReactSelectProps[]>( [ ] );

    const [usuarioExpedienteVirtual, setUsuarioExpedienteVirtual] = useState<string>( '' );

    const [errors, setErrors] = useState<Errors>({ });

    const cleanFields = () => {

        setBuscarUsuario( false );
        setCurp( '' );
        setNombres( '' );
        setApellido1( '' );
        setApellido2( '' );
        setCorreoElectronico( '' );
        setTelefonoCelular( '' );
        
        setTipoUsuario( 0 );
        setTipoUsuarioTemp( 0 );
        setPuesto( 0 );
        setPuestoTemp( 0 );
        
        setIdFirma( '' );
        setContrasenaFirma( '' );
        setFechaExpiraFirma( '' );
        setViewContrasenaFirma( false );
                            
        setContrasena( '' );
        setViewContrasena( false );
        setConfirmarContrasena( '' );
        setViewConfirmarContrasena( false );

        setCheckedActivar( true );
        setCheckedRestablecer( true );
        setUsuarioExpedienteVirtual( '' );
        
        setRolesSelected( [] );
        setErrors( {} );

    }

    const validateFielsBuscar = () => {
        
        setErrors( { } );

        let valid = true;
        let errores: Errors = {};

        if( curpSearch === '' ){
            valid = false;
            errores.curp = 'Debes escribir la CURP';
        }      
        else if( !curpValida( curpSearch ) ) {
            valid = false;
            errores.curp = 'La CURP no es valida';
        }
        
        setErrors( errores );
        return valid;
    } 

    const buscarUsuarioRRHH = async () => {

        const validFiels = validateFielsBuscar();

        if( !validFiels ){
            return false;
        }        

        setIsLoadingBuscar( true );

        await BuscarUsuarioRH( curpSearch ).then( resp => {

            if( resp ){

                if( resp.success ){

                    const { success, nombres, apellido_Primero, apellido_Segundo, email, curp } = resp;

                    if( success ){                        
                        
                        setTimeout(() => {

                            setCurp( curp ?? '' );
                            setNombres( nombres ?? '' );
                            setApellido1( apellido_Primero ?? '' );
                            setApellido2( apellido_Segundo ?? '' );
                            setCorreoElectronico( email ?? '' );
                    
                            setCurpSearch('');

                            setIsLoadingBuscar( false );

                        }, 100);
                    }
                    else{                        

                        let errores: Errors = {};                      
                        errores.api = `No se encontro la persona con el CURP: ${ curpSearch }`;                       
                        
                        setErrors( errores );
                        setIsLoadingBuscar( false );
                    }

                }

            }

        });
        
    }

    const NoOptionsMessage = ( props: any ) => {
        return (
            <components.NoOptionsMessage {...props}>
                <Typography fontSize='14px' sx={{ color: 'gray.600', fontWeight: 'bold' }}>No se encontraron registros</Typography> 
            </components.NoOptionsMessage>
        );
    };

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores: Errors = {};

        if( opcion === 'Agregar' ){

            if( nombres === '' ){
                valid = false;
                errores.nombres = 'Debes escribir el nombre';
            } 
            if( apellido1 === '' ){
                valid = false;
                errores.apellido1 = 'Debes escribir el apellido 1';
            } 
            if( telefonoCelular === '' ){
                valid = false;
                errores.telefonoCelular = 'Debes escribir el teléfono celular';
            } 
            if( !curpValida( curp ) ) {
                valid = false;
                errores.curp = 'La CURP no es valida';
            }
        }

        if( correoElectronico === '' ){
            valid = false;
            errores.correoElectronico = 'Debes escribir el correo electrónico';
        } 
        if( tipoUsuario === 0 ){
            valid = false;
            errores.tipoUsuario = 'Debes seleccionar el tipo de usuario';
        }      
        if( tipoUsuario !== 1 && puesto === 0 ){
            valid = false;
            errores.puesto = 'Debes seleccionar el puesto';
        }
        
        if( opcion === 'Editar' ){
            if( contrasena === '' ){
                valid = false;
                errores.contrasena = 'Debes escribir la contraseña';
            }
            else if( confirmarContrasena === '' ){
                valid = false;
                errores.contrasena = 'Debes confirmar la contraseña';
            }
            else if( contrasena !== confirmarContrasena ){
                valid = false;
                errores.contrasena = 'Las contraseñas no coinciden';
            }
        }
       
        if( ( idFirma !== '' ) && contrasenaFirma === ''  ){
            valid = false;
            errores.contrasenaFirma = 'Debes escribir la contraseña de la firma electronica';
        }
        else if( contrasenaFirma !== '' && idFirma === '' ){
            valid = false;
            errores.idFirmaElectronica = 'Debes escribir el ID de la firma electronica';
        }
        else if( idFirma !== '' && contrasenaFirma !== '' && fechaExpiraFirma === '' ){
            valid = false;
            errores.fechaExpiraFirma = 'Debes seleccionar la fecha de expiración de la firma electrónica';
        }
        
        setErrors( errores );
        return valid;
    } 

    const GuardarUsuario = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        setIsSaving( true );

        if( opcion === 'Agregar' ){

            const entidad = curp ? ( ObtenerDescripcionEntidad( curp.substring(11, 13) )?.id ?? 0 ) : 0;
            const fec_nac = curpFechaNacimiento( curp );
            const sexo = curp.substring(10, 11) === 'H' ? 1 : 2;

            const data: AgregarUsuarioParams = {
                nombres: nombres,
                apellido1: apellido1,
                apellido2: apellido2,
                curp: curp,
                entidadNacimiento: entidad,
                fechaNacimiento: fec_nac ? moment( fec_nac ).format('YYYY-MM-DD') : '',
                idSexo: sexo,
                correoElectronico : correoElectronico,
                contrasenaFirmaElectronica : contrasenaFirma,
                activo : checkedActivar,
                idUsuarioFirmaElectronica : idFirma === '' ? 0 : parseInt(idFirma),
                idPuesto : puesto,
                idTipoUsuario : tipoUsuario,
                idUsuarioExpedienteVirtual: usuarioExpedienteVirtual === '' ? 0 : parseInt(usuarioExpedienteVirtual),
                restablecerContrasena : checkedRestablecer,
                fechaExpiracionFirmaElectronica: fechaExpiraFirma,
                telefonoCelular: FormatPhoneNumber( telefonoCelular ),
                roles : rolesSelected.map(({ value }) => value ),
            }

            await AgregarUsuario( data ).then( resp => {

                if( resp.success ){

                    setTimeout(() => {
                
                        setOpen( false );                      
                       
                        setUsuarios([
                            ...usuarios,
                            {
                                id: resp.id,
                                idUsuario: resp.idUsuario,
                                nombreCompleto: ( nombres + ' ' + apellido1 + ' ' + apellido2 ).trim(),
                                correoElectronico: correoElectronico,
                                imagenPerfil: '',
                                puesto: puestosArray.find( ( e ) => e.idPuesto === puesto )?.descripcion ?? '',
                                tipoUsuario: tiposUsuariosArray.find( ( e ) => e.idTipoUsuario === tipoUsuario )?.descripcion ?? '',
                                activo: checkedActivar,          
                                administrador: tipoUsuario === 1,    
                            }
                        ]);

                        setOpenMessage({ type: 'success', open: true, message: 'Se guardó la configuración correctamente' });

                        setIsSaving( false );
                        
                    }, 400);   
                    
                }
                else{

                    setTimeout(() => {
                    
                        setOpenMessage({ type: 'warning', open: true, message: resp.message ?? '' });
                        
                        setIsSaving( false );

                    }, 400);   
                    
                }            

            });

        }

        if( opcion === 'Editar' ){        

            const data: ActualizarUsuarioParams = {
                id: referencia,
                correoElectronico : correoElectronico,
                contrasena : contrasena,
                contrasenaFirmaElectronica : contrasenaFirma,
                activo : checkedActivar,
                idUsuarioFirmaElectronica : idFirma === '' ? 0 : parseInt(idFirma),
                idPuesto : puesto,
                idTipoUsuario : tipoUsuario,
                idUsuarioExpedienteVirtual: usuarioExpedienteVirtual === '' ? 0 : parseInt(usuarioExpedienteVirtual),
                restablecerContrasena : checkedRestablecer,
                telefonoCelular: FormatPhoneNumber( telefonoCelular ),
                roles : rolesSelected.map(({ value }) => value ),
                fechaExpiracionFirmaElectronica: fechaExpiraFirma,
            }

            await ActualizarUsuario( data ).then( resp => {
            
                if( resp.success ){

                    setTimeout(() => {

                        setOpen( false );
                                        
                        const newArray = usuarios.map( ( u ) => {
                            if( u.id === referencia ){
                                u.correoElectronico = correoElectronico;
                                u.puesto = puestosArray.find( ( e ) => e.idPuesto === puesto )?.descripcion ?? '';
                                u.tipoUsuario = tiposUsuariosArray.find( ( e ) => e.idTipoUsuario === tipoUsuario )?.descripcion ?? '';
                            }
                            return u;
                        });
                        
                        setUsuarios( newArray );

                        setOpenMessage({ type: 'success', open: true, message: 'Se guardó la configuración correctamente' });

                        setIsSaving( false );
                        
                    }, 400);   
                    
                }
                else{

                    setTimeout(() => {
                    
                        setOpenMessage({ type: 'warning', open: true, message: resp.message ?? '' });
                        
                        setIsSaving( false );

                    }, 400);   
                    
                }            

            });

        }

    }

    useEffect(() => {
        
        async function Obtener(){         

            await ObtenerTiposUsuarios().then( resp => {               
                  
                setTiposUsuariosArray( resp );
                    
            });
        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){         

            await ObtenerPuestos().then( resp => {               
                  
                setPuestosArray( resp );
                    
            });
        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){         

            await ObtenerRoles().then( resp => {     

                if( resp.length !== 0 ){
                   
                    const newArray: ReactSelectProps[] = resp.map( ( e ) => {  
                        return {
                            value: e.idRol,
                            label: e.descripcion
                        };
                    });

                    setRoles( newArray );                    
                }

            });
        }

        Obtener();

    }, [ ]);

    useEffect(() => {      
        
        async function Obtener(){   

            setIsLoading( true );
            
            await ObtenerUsuario( id ?? '' ).then( resp => {

                if( resp.success ){

                    setReferencia( id ?? '' );
                    setCurp( resp.curp ?? '' );
                    setNombres( resp.nombres ?? '' );
                    setApellido1( resp.apellido1 ?? '' );
                    setApellido2( resp.apellido2 ?? '' );
                    setCorreoElectronico( resp.correoElectronico ?? '' );
                    setTelefonoCelular( ClearFormatPhoneNumber( resp.telefonoCelular ?? '' ) );

                    setTipoUsuarioTemp( resp.idTipoUsuario ?? 0 );
                    setPuestoTemp( resp.idPuesto ?? 0 );
                   
                    setIdFirma( ( resp.idUsuarioFirmaElectronica?.toString() ?? '') === '0' ? '' : resp.idUsuarioFirmaElectronica?.toString() ?? '' );
                    setContrasenaFirma( resp.contrasenaFirmaElectronica ?? '' );
                    setFechaExpiraFirma( resp.fechaExpiracionFirmaElectronica ?? '' );       
                    
                    setContrasena( resp.contrasena ?? '' );
                    setConfirmarContrasena( resp.contrasena ?? '' );

                    setCheckedActivar( resp.activo ?? false );
                    setCheckedRestablecer( resp.restablecerContrasena ?? false );
                    setUsuarioExpedienteVirtual( ( resp.idUsuarioExpedienteVirtual?.toString() ?? '') === '0' ? '' : resp.idUsuarioExpedienteVirtual?.toString() ?? '' );

                    if( resp.roles?.length !== 0){

                        const optionsDefault = resp.roles?.filter( ({ selected }) => { return selected === true; } ) ?? [];
                        const newArray: ReactSelectProps[] = optionsDefault.map( ( { id, descripcion } ) => {
                            return {
                                value: id,
                                label: descripcion,
                            }
                        });

                        setRolesSelected( newArray );
                    }

                    setTimeout(() => {
                        setIsLoading( false );
                    }, 700);
                }

            });
        }

        if( open && id && opcion === 'Editar'){
            Obtener();
        }

    }, [ id, opcion, open ])    

    useEffect(() => {
        
        if( tipoUsuarioTemp !== 0 && tiposUsuariosArray.length !== 0){
            setTipoUsuario( tipoUsuarioTemp );
        }

    }, [ tipoUsuarioTemp, tiposUsuariosArray ]);

    useEffect(() => {
        
        if( puestoTemp !== 0 && puestosArray.length !== 0){
            setPuesto( puestoTemp );
        }

    }, [ puestoTemp, puestosArray ]);

    return (
        <Modal
            disableEscapeKeyDown                
            open={ open }
            onClose={ ( _, r ) =>{ 
                if( r === 'backdropClick' ) return;                     
                setOpen( false )
            }}
            sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
        >
            <ModalOverflow>
            
                <ModalDialog
                    aria-labelledby="server-modal-title"
                    aria-describedby="server-modal-description"
                    layout="center"
                    size='lg'
                >
                    
                    {
                        isLoading
                        ?
                            <Grid container spacing={3} sx={{ maxHeight: '550px', width: { xs: 'auto', lg: '900px' } }}>
                                <Grid xs={12} sx={{ textAlign: 'center', p: '200px' }}>
                                    <CircularProgress color='neutral' size='md' />
                                </Grid>
                            </Grid>
                        :        
                            <Grid container spacing={3} sx={{ maxHeight: '550px', width: { xs: 'auto', lg: '900px' }, overflow: 'scroll' }}>

                                <Grid lg={12} md={12} xs={12}>   

                                    <Card>
                                        <Typography sx={{ fontWeight: 'bold' }}> Información de la cuenta </Typography> 
                                        
                                        <Divider />
                                    
                                        <CardContent>     
                                        
                                            <Grid container spacing={3} mt={1}>

                                                {
                                                    opcion === 'Agregar'
                                                    &&
                                                    <>
                                                        <Grid md={5} xs={12}>

                                                            <FormControl>      
                                                                <FormLabel sx={{ fontSize: 16 }}>¿Buscar nuevo usuario en RRHH?</FormLabel>                                          
                                                                <RadioGroup defaultValue="Si" onChange={ ( ) => { setCurpSearch( '' ); } } >
                                                                    <List component='div' orientation='horizontal' >
                                                                        <Radio color='neutral' value="Si" label="Si" variant="outlined" size='md' sx={{ mr: 1 }} checked={ buscarUsuario === true } onChange={ () => setBuscarUsuario( true ) } />                                                            
                                                                        <Radio color='neutral' value="No" label="No" variant="outlined" size='md' sx={{ ml: 1 }} checked={ buscarUsuario === false } onChange={ () => setBuscarUsuario( false ) } />
                                                                    </List>
                                                                </RadioGroup>
                                                            </FormControl>        

                                                        </Grid>

                                                        <Grid md={7} xs={12}>

                                                            <FormControl>
                                                                <FormLabel sx={{ fontSize: 16 }}>
                                                                    Buscar usuario
                                                                </FormLabel>
                                                                <Input
                                                                    sx={{ '--Input-decoratorChildHeight': '45px' }}
                                                                    placeholder="Buscar por CURP"    
                                                                    autoComplete="off"     
                                                                    endDecorator={
                                                                        <Button
                                                                            variant="solid"
                                                                            color="neutral"                   
                                                                            sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                            loading={ isLoadingBuscar }
                                                                            onClick={ buscarUsuarioRRHH }
                                                                        >
                                                                            Buscar
                                                                        </Button>
                                                                    }
                                                                    disabled={ !buscarUsuario }
                                                                    value={ curpSearch }
                                                                    onChange={ ( e ) => setCurpSearch( e.target.value.toUpperCase() ) }
                                                                />        
                                                            </FormControl>

                                                            {
                                                                errors.api && (
                                                                    <Alert color='danger' variant="soft" sx={{ mt: 1 }}>
                                                                        { errors.api }
                                                                    </Alert>
                                                                )
                                                            }

                                                            {
                                                                errors.curp && (
                                                                    <Alert color='warning' variant="soft" sx={{ mt: 1 }}>
                                                                        { errors.curp }
                                                                    </Alert>
                                                                )
                                                            }

                                                        </Grid>
                                                
                                                        <Grid md={12} xs={12} >
                                                            <FormControl>
                                                                <FormLabel sx={{ fontSize: 16 }} id="nombres-label">Nombre(s)</FormLabel>
                                                                <Input
                                                                    size={'lg'}        
                                                                    placeholder=''                     
                                                                    name="nombres"
                                                                    variant="outlined"
                                                                    autoComplete="off"
                                                                    required
                                                                    disabled={ buscarUsuario }
                                                                    value={ nombres }
                                                                    onChange={ ( e ) => setNombres( e.target.value.toUpperCase() ) }
                                                                />
                                                            </FormControl>
                                                            {   
                                                                errors.nombres && 
                                                                ( 
                                                                    <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                                        { errors.nombres } 
                                                                    </Alert> 
                                                                )
                                                            }
                                                        </Grid>    

                                                        <Grid md={4} xs={12} >
                                                            <FormControl>
                                                                <FormLabel sx={{ fontSize: 16 }} id="apellido1-label">Apellido 1</FormLabel>
                                                                <Input
                                                                    size={'lg'}        
                                                                    placeholder=''                     
                                                                    name="apellido1"
                                                                    variant="outlined"
                                                                    autoComplete="off"                                                            
                                                                    required
                                                                    disabled={ buscarUsuario }
                                                                    value={ apellido1 }
                                                                    onChange={ ( e ) => setApellido1( e.target.value.toUpperCase() ) }
                                                                />
                                                            </FormControl>
                                                            {   
                                                                errors.apellido1 && 
                                                                ( 
                                                                    <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                                        { errors.apellido1 } 
                                                                    </Alert> 
                                                                )
                                                            }
                                                        </Grid>    

                                                        <Grid md={4} xs={12} >
                                                            <FormControl>
                                                                <FormLabel sx={{ fontSize: 16 }} id="apellido2-label">Apellido 2</FormLabel>
                                                                <Input
                                                                    size={'lg'}        
                                                                    placeholder=''                     
                                                                    name="apellido2"
                                                                    variant="outlined"
                                                                    autoComplete="off"
                                                                    disabled={ buscarUsuario }
                                                                    value={ apellido2 }
                                                                    onChange={ ( e ) => setApellido2( e.target.value.toUpperCase() ) }
                                                                />
                                                            </FormControl>
                                                        </Grid>  

                                                        <Grid md={4} xs={12} >
                                                            <FormControl>
                                                                <FormLabel sx={{ fontSize: 16 }} id="curp-label">CURP</FormLabel>
                                                                <Input
                                                                    size={'lg'}        
                                                                    placeholder=''                     
                                                                    name="curp"
                                                                    variant="outlined"
                                                                    autoComplete="off"                                                            
                                                                    disabled={ buscarUsuario }
                                                                    value={ curp }
                                                                    onChange={ ( e ) => setCurp( e.target.value.toUpperCase() ) }
                                                                />
                                                            </FormControl>
                                                        </Grid>                                             
                                                    </>
                                                }

                                                <Grid md={12} xs={12} >
                                                    <FormControl>
                                                        <FormLabel sx={{ fontSize: 16 }} id="correoElectronico-label">Correo electrónico</FormLabel>
                                                        <Input
                                                            size={'lg'}        
                                                            placeholder=''                     
                                                            name="correoElectronico"
                                                            variant="outlined"
                                                            type='email'
                                                            autoComplete="off"
                                                            required
                                                            value={ correoElectronico }
                                                            onChange={ ( e ) => setCorreoElectronico( e.target.value ) }
                                                        />
                                                    </FormControl>
                                                    {   
                                                        errors.correoElectronico && 
                                                        ( 
                                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                                { errors.correoElectronico } 
                                                            </Alert> 
                                                        )
                                                    }
                                                </Grid>    

                                                <Grid md={6} xs={12} >                            
                                                    <FormControl>
                                                        <FormLabel sx={{ fontSize: 16 }} id="select-tipoUsuario-label">Tipo de Usuario</FormLabel>
                                                    
                                                        <Select 
                                                            name="tipoUsuario"          
                                                            size='lg'  
                                                            slotProps={{ listbox: { sx: { width: '100%', zIndex: 99999999 } } }}     
                                                            value={ tipoUsuario }      
                                                            onChange={ ( _, value ) => { 

                                                                if( value === 1 ){
                                                                    setPuesto( 0 );
                                                                }

                                                                setTipoUsuario( value ?? 0 ); 
                                                            }}  
                                                        >
                                                            <Option key={0} value={0}>Selecciona una opción</Option>
                                                            {
                                                                tiposUsuariosArray.map( ( elem ) => (
                                                                    <Option key={ elem.idTipoUsuario } value={ elem.idTipoUsuario }> { elem.descripcion } </Option>
                                                                ))
                                                            }
                                                        </Select>

                                                    </FormControl>
                                                    {   
                                                        errors.tipoUsuario && 
                                                        ( 
                                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                                { errors.tipoUsuario } 
                                                            </Alert> 
                                                        )
                                                    }
                                                </Grid>                                     

                                                <Grid md={6} xs={12} >                            
                                                    <FormControl>
                                                        <FormLabel sx={{ fontSize: 16 }} id="select-puesto-label">Puesto</FormLabel>
                                                    
                                                        <Select                      
                                                            name="puesto"             
                                                            size='lg'    
                                                            slotProps={{ listbox: { sx: { width: '100%', zIndex: 99999999 } } }}    
                                                            value={ puesto }      
                                                            onChange={ ( _, value ) => { setPuesto( value ?? 0 ); } }      
                                                            disabled={ tipoUsuario === 1 }                                           
                                                        >              
                                                            <Option key={0} value={0}>Selecciona una opción</Option>
                                                            {
                                                                puestosArray.map( ( elem ) => (
                                                                    <Option key={ elem.idPuesto } value={ elem.idPuesto }> { elem.descripcion } </Option>
                                                                ))
                                                            }                                
                                                        </Select>

                                                    </FormControl>
                                                    {   
                                                        errors.puesto && 
                                                        ( 
                                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                                { errors.puesto } 
                                                            </Alert> 
                                                        )
                                                    }
                                                </Grid>  

                                                <Grid md={6} xs={6} >                            
                                                    <FormControl orientation="horizontal" sx={{ mr: 5 }} >
                                                        <FormLabel sx={{ fontSize: 16 }}>Activar</FormLabel>
                                                        <Switch size='lg' checked={ checkedActivar } onChange={ ( e ) => setCheckedActivar( e.target.checked ) } />
                                                    </FormControl>      
                                                </Grid>  

                                                <Grid md={6} xs={6} >  
                                                    <FormControl orientation="horizontal" sx={{ mr: 5 }} >
                                                        <FormLabel sx={{ fontSize: 16 }}>Restablecer contraseña</FormLabel>
                                                        <Switch size='lg' checked={ checkedRestablecer } onChange={ ( e ) => setCheckedRestablecer( e.target.checked ) } />
                                                    </FormControl>    
                                                </Grid>       

                                                <Grid md={6} xs={12} >

                                                    <FormControl>
                                                        <FormLabel sx={{ fontSize: 16 }}>ID Usuario de la Firma Electrónica</FormLabel>
                                                        <Input
                                                            size={'lg'}
                                                            name="usuarioFirma"                                
                                                            type="number"
                                                            variant="outlined"       
                                                            autoComplete="off"
                                                            value={ idFirma }
                                                            onChange={ ( e ) => setIdFirma( e.target.value ) }                   
                                                        />
                                                    </FormControl>
                                                    {   
                                                        errors.idFirmaElectronica && 
                                                        ( 
                                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                                { errors.idFirmaElectronica } 
                                                            </Alert> 
                                                        )
                                                    }

                                                </Grid>  

                                                <Grid md={6} xs={12} >
                                                    <FormControl>
                                                        <FormLabel sx={{ fontSize: 16 }}>Contraseña de la Firma Electrónica</FormLabel>
                                                        <Input
                                                            size={'lg'}
                                                            name="contrasenaFirma"
                                                            type={ viewContrasenaFirma ? 'text' : 'password'}                                              
                                                            endDecorator={                                                  
                                                                <IconButton onClick={ () => setViewContrasenaFirma( ( prev ) => !prev ) } >   
                                                                    { 
                                                                        viewContrasenaFirma 
                                                                        ?
                                                                            <VisibilityOffIcon />
                                                                        :
                                                                            <VisibilityIcon />    
                                                                    }                                                                                                        
                                                                </IconButton>
                                                            }
                                                            value={ contrasenaFirma }
                                                            onChange={ ( e ) => setContrasenaFirma( e.target.value ) }
                                                        />
                                                    </FormControl>
                                                    {   
                                                        errors.contrasenaFirma && 
                                                        ( 
                                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                                { errors.contrasenaFirma } 
                                                            </Alert> 
                                                        )
                                                    }
                                                </Grid>  

                                                <Grid md={12} xs={12} >
                                                    <FormControl>
                                                        <FormLabel sx={{ fontSize: 16 }}>Fecha expiración de la Firma Electrónica</FormLabel>
                                                        <Input
                                                            size={'lg'}
                                                            name="fechaExpiraFirma"
                                                            type={ 'date' }   
                                                            value={ fechaExpiraFirma }
                                                            
                                                            onChange={ ( e ) => setFechaExpiraFirma( e.target.value ) }
                                                        />
                                                    </FormControl>
                                                    {   
                                                        errors.fechaExpiraFirma && 
                                                        ( 
                                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                                { errors.fechaExpiraFirma } 
                                                            </Alert> 
                                                        )
                                                    }
                                                </Grid>  

                                                <Grid md={6} xs={12} >

                                                    <FormControl>
                                                        <FormLabel sx={{ fontSize: 16 }}>Teléfono celular</FormLabel>
                                                        <Input
                                                            size={'lg'}
                                                            name="usuarioFirma"                                
                                                            type="number"
                                                            variant="outlined"       
                                                            autoComplete="off"                                                            
                                                            value={ telefonoCelular }
                                                            onChange={ ( e ) => { 
                                                                if( e.target.value.length > 10 ) return;                                                            
                                                                setTelefonoCelular( e.target.value ) } 
                                                            }                
                                                        />
                                                    </FormControl>
                                                    {   
                                                        errors.telefonoCelular && 
                                                        ( 
                                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                                { errors.telefonoCelular } 
                                                            </Alert> 
                                                        )
                                                    }

                                                </Grid>

                                                <Grid md={6} xs={12} >

                                                    <FormControl>
                                                        <FormLabel sx={{ fontSize: 16 }}>ID Usuario Poder en Línea (Expediente virtual)</FormLabel>
                                                        <Input
                                                            size={'lg'}
                                                            name="usuarioFirma"                                
                                                            type="number"
                                                            variant="outlined"       
                                                            autoComplete="off"
                                                            value={ usuarioExpedienteVirtual }
                                                            onChange={ ( e ) => { setUsuarioExpedienteVirtual( e.target.value ) } }                
                                                        />
                                                    </FormControl>

                                                </Grid>

                                            </Grid>                                   

                                        </CardContent>

                                    </Card>

                                </Grid>

                                {
                                    opcion === 'Editar'
                                    &&
                                        <Grid lg={12} md={12} xs={12} >                                    
                                            <Card>
                                                <Typography sx={{ fontWeight: 'bold' }}> Cambiar contraseña </Typography>
                                                
                                                <Divider />
                                            
                                                <CardContent>

                                                    <Grid container spacing={3} >

                                                        <Grid md={6} xs={12} >

                                                            <FormControl>
                                                                <FormLabel sx={{ fontSize: 16 }}>Contraseña</FormLabel>
                                                                <Input
                                                                    size={'lg'}
                                                                    name="contrasena"
                                                                    autoComplete="off"
                                                                    type={ viewContrasena ? 'text' : 'password'}                                              
                                                                    endDecorator={                                                  
                                                                        <IconButton onClick={ () => setViewContrasena( ( prev ) => !prev ) } >   
                                                                            { 
                                                                                viewContrasena
                                                                                ?
                                                                                    <VisibilityOffIcon />
                                                                                :
                                                                                    <VisibilityIcon />    
                                                                            }                                                                                                        
                                                                        </IconButton>
                                                                    }
                                                                    value={ contrasena }
                                                                    onChange={ ( e ) => setContrasena( e.target.value ) }
                                                                />
                                                            </FormControl>
                                                            
                                                        </Grid>                                            

                                                        <Grid md={6} xs={12} >
                                                            
                                                            <FormControl>
                                                                <FormLabel sx={{ fontSize: 16 }}>Confirmar contraseña</FormLabel>
                                                                <Input
                                                                    size={'lg'}
                                                                    name="contrasena"
                                                                    autoComplete="off"
                                                                    type={ viewConfirmarContrasena ? 'text' : 'password'}                                              
                                                                    endDecorator={                                                  
                                                                        <IconButton onClick={ () => setViewConfirmarContrasena( ( prev ) => !prev ) } >    
                                                                            { 
                                                                                viewConfirmarContrasena 
                                                                                ?
                                                                                    <VisibilityOffIcon />
                                                                                :
                                                                                    <VisibilityIcon />    
                                                                            }                                                                                                        
                                                                        </IconButton>
                                                                    }
                                                                    value={ confirmarContrasena }
                                                                    onChange={ ( e ) => setConfirmarContrasena( e.target.value ) }
                                                                />
                                                            </FormControl>
                                                        
                                                        </Grid>  

                                                        {   
                                                            errors.contrasena && 
                                                            ( 
                                                                <Grid xs={12} >
                                                                    <Alert color='warning' variant="soft"> 
                                                                        { errors.contrasena } 
                                                                    </Alert> 
                                                                </Grid>
                                                            )
                                                        }

                                                    </Grid>  

                                                </CardContent>

                                            </Card>

                                        </Grid>
                                }

                                <Grid lg={12} md={12} xs={12} >        

                                    <Card>

                                        <Typography sx={{ fontWeight: 'bold' }}> Permisos </Typography>
                                        
                                        <Divider />
                                    
                                        <CardContent>

                                            <Grid container spacing={3} >

                                                <Grid md={12} xs={12} >

                                                    <ReactSelect
                                                        isMulti
                                                        menuPlacement='top'
                                                        placeholder='Seleccione los permisos para el usuario'
                                                        closeMenuOnSelect={ false }
                                                        styles={ colourStyles }
                                                        components={{ NoOptionsMessage }}
                                                        options={ roles }
                                                        value={ rolesSelected }
                                                        onChange={ ( value ) => setRolesSelected([ ...value ]) }
                                                    />

                                                </Grid>  

                                            </Grid>  

                                        </CardContent>

                                    </Card>

                                </Grid>

                            </Grid>   
                    }                         

                    <DialogActions>

                        <Button color='neutral' onClick={ GuardarUsuario } loading={ isSaving}>
                            Guardar
                        </Button>

                        <Button variant='plain' color='neutral' onClick={ () => { setOpen( false ); cleanFields(); } }>
                            Cancelar
                        </Button>

                    </DialogActions>

                </ModalDialog>
                
            </ModalOverflow>

        </Modal>    
    )
}